import { Icon, IconNewTab } from '@flixbus/honeycomb-icons-react';
import { Grid, GridCol, Heading, Link, Text } from '@flixbus/honeycomb-react';

import Header from '../legal/Header';

function Design() {
  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Home', link: '/' },
          { name: 'Design', link: '/design' },
        ]}
        title="Design"
      />
      <main>
        <Grid>
          <GridCol size={4} extraClasses="hcr-space-4-bottom">
            <Heading size={2}>Style guide</Heading>
            <Text>
              Logos, Icons, Colors, Font, Components
              <br />
              <Link target="_blank" href="https://styleguide.hive.flix.tech">
                Browse style guide
                <Icon style={{ fill: 'currentColor' }} InlineIcon={IconNewTab} aria-label="Opens in a New Tab" />
              </Link>
            </Text>
          </GridCol>
          <GridCol size={4} extraClasses="hcr-space-4-bottom">
            <Heading size={2}>Media library</Heading>
            <Text>
              Branded images
              <br />
              <Link target="_blank" href="https://corporate.flix.com/flix-media-library/">
                Browse media library
                <Icon style={{ fill: 'currentColor' }} InlineIcon={IconNewTab} aria-label="Opens in a New Tab" />
              </Link>
            </Text>
          </GridCol>
          <GridCol size={4} extraClasses="hcr-space-4-bottom">
            <Heading size={2}>FlixBrand</Heading>
            <Text>
              Everything you need to utilize the brand in the correct way
              <br />
              <Link target="_blank" href="https://flixbrand.com/">
                View our brand style guide
                <Icon style={{ fill: 'currentColor' }} InlineIcon={IconNewTab} aria-label="Opens in a New Tab" />
              </Link>
            </Text>
          </GridCol>
        </Grid>
      </main>
    </>
  );
}

export default Design;
