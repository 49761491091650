import useApiClient from './useApiClient';

const useSubscriptionService = () => {
  const { apiClient } = useApiClient();

  const getSubscription = async (apiId) => {
    let subscription = await apiClient().then((client) => client.get(`/subscriptions?resourceId=${apiId}`));
    if (subscription.data.length === 0) {
      return {
        resourceType: 'api',
        resourceId: apiId,
        resourceAttributes: [],
      };
    }
    return subscription.data[0];
  };

  const saveSubscription = (type, name, attributes) => {
    let body = { resourceType: type, resourceId: name, resourceAttributes: attributes };
    return apiClient().then((client) => client.post('/subscriptions', body));
  };

  const deleteSubscription = (type, name) => {
    if (name === undefined) {
      const parts = type.split('-');
      name = type;
      type = parts[1];
    }
    let body = { resourceType: type, resourceId: name };
    return apiClient().then((client) => client.delete('/subscriptions', { data: body }));
  };

  return {
    getSubscription: getSubscription,
    saveSubscription: saveSubscription,
    deleteSubscription: deleteSubscription,
  };
};

export default useSubscriptionService;
