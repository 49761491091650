import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';

import { request } from '../authConfig';

export function useIsInternalUser() {
  const { instance } = useMsal();
  const [isInternalUser, setIsInternalUser] = useState(false);

  useEffect(() => {
    async function getToken() {
      try {
        const response = await instance.acquireTokenSilent({
          ...request,
          redirectUri: document.location.origin + '/blank.html',
          account: instance.getActiveAccount(),
        });
        const decodedToken = jwtDecode(response.accessToken);
        const found = decodedToken.roles.find((role) => role.endsWith('.Internal'));
        setIsInternalUser(found !== undefined);
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          // Fallback to interactive redirect flow if silent acquisition fails
          instance.acquireTokenRedirect({ ...request, account: instance.getActiveAccount() });
        } else {
          // Handle other errors
          console.error('Error acquiring token:', error);
        }
      }
    }

    getToken();
  }, [instance, isInternalUser]);

  return isInternalUser;
}
