import { Button } from '@flixbus/honeycomb-react';
import * as React from 'react';

function CollectionViewButton(props) {
  return (
    <Button Elem="a" href={props.link} target="_blank">
      View collection
    </Button>
  );
}

export default CollectionViewButton;
