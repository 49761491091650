import { Breadcrumbs, BreadcrumbsItem, Divider, Grid, GridCol, Heading, Text } from '@flixbus/honeycomb-react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Contact from '../layout/Contact';

function BasicsHeader(props) {
  return (
    <section>
      <Breadcrumbs aria-label="breadcrumbs" extraClasses="hcr-space-2-bottom">
        {props.breadcrumbs.map((breadcrumbsItem) => {
          return (
            <BreadcrumbsItem key={breadcrumbsItem.name} RouterLink={RouterLink} to={breadcrumbsItem.link}>
              {breadcrumbsItem.name}
            </BreadcrumbsItem>
          );
        })}
      </Breadcrumbs>

      <Grid>
        <GridCol size={12}>
          <Heading size={1} extraClasses="hcr-space-1-bottom">
            {props.title}
          </Heading>
        </GridCol>
        <GridCol>
          <Text extraClasses="hcr-space-3-bottom">{props.subtitle}</Text>
        </GridCol>
        <GridCol extraClasses="hcr-has-text-right">
          <Contact type={props.resourceType} />
        </GridCol>
      </Grid>
      <Divider extraClasses="hcr-space-4-bottom" />
    </section>
  );
}

export default BasicsHeader;
