import { Breadcrumbs, BreadcrumbsItem, Grid, GridCol, Heading, Tag, Text } from '@flixbus/honeycomb-react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Contact from '../layout/Contact';
import styles from './FlowDetailsHeader.module.css';

function FlowDetailsHeader(props) {
  let uniqueProviders = new Set();
  props.flow.data.apis.map((api) => {
    return api.providers.map((provider) => {
      return uniqueProviders.add(provider);
    });
  });

  return (
    <>
      <Breadcrumbs aria-label="breadcrumbs" extraClasses="hcr-space-2-bottom">
        {props.breadcrumbs.map((breadcrumbsItem) => {
          return (
            <BreadcrumbsItem key={breadcrumbsItem.name} RouterLink={RouterLink} to={breadcrumbsItem.link}>
              {breadcrumbsItem.name}
            </BreadcrumbsItem>
          );
        })}
      </Breadcrumbs>
      <Grid extraClasses={styles['flow-heading']}>
        <GridCol size={6} xl={6}>
          <section className={styles['heading-tags-container']}>
            <Heading size={1} extraClasses={'hcr-space-1-right, hcr-space-1-bottom'}>
              {props.flow.data.title}
            </Heading>
            {Array.from(uniqueProviders).map((provider) => {
              return (
                <Tag key={provider} small extraClasses={styles['flow-details-teams-tags']}>
                  {provider}
                </Tag>
              );
            })}
          </section>
        </GridCol>
        <GridCol size={3} xl={6}>
          <section className={styles['includes-api-container']}>
            <Text extraClasses="hcr-space-flush-bottom hcr-space-1-right">This flow uses following APIs: </Text>
            {props.flow.data.apis.map((api) => {
              return (
                <Tag key={api.name} small extraClasses={styles['flow-details-teams-tags']}>
                  {api.name}
                </Tag>
              );
            })}
          </section>
        </GridCol>
      </Grid>
      <Grid extraClasses={'hcr-space-2-bottom'}>
        <GridCol size={6} xl={9}>
          <Text>{props.flow.data.subtitle}</Text>
        </GridCol>
        <GridCol size={3} xl={3}>
          <section className={styles['includes-api-container']}>
            <Contact type="flow" />
          </section>
        </GridCol>
      </Grid>
    </>
  );
}

export default FlowDetailsHeader;
