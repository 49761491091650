import { Button, Grid, GridCol } from '@flixbus/honeycomb-react';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import SwaggerEditor from 'swagger-editor';
import 'swagger-editor/dist/swagger-editor.css';

import './SpecPreview.css';

function SpecPreview() {
  useEffect(() => {
    SwaggerEditor({
      dom_id: '#swagger-editor',
      layout: 'EditorLayout',
    });
  }, []);

  const saveContent = () => {
    window.localStorage.getItem('swagger-editor-content');
  };

  return (
    <Grid>
      <GridCol size={6} extraClasses="editor-tool-bar">
        <Button appearance="primary" to={'/preview/reference'} RouterLink={NavLink} onClick={saveContent}>
          Render API Reference
        </Button>
      </GridCol>
      <GridCol size={12} extraClasses="swagger-editor-col">
        <div id="swagger-editor"></div>
      </GridCol>
    </Grid>
  );
}

export default SpecPreview;
