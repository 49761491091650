import { Breadcrumbs, BreadcrumbsItem, Grid, GridCol, Heading, Text } from '@flixbus/honeycomb-react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import styles from './FlowsHeader.module.css';

function FlowsHeader(props) {
  return (
    <section className={props.isRequiredForHomePage ? styles['flows-header-home-page'] : styles['flows-header']}>
      {props.withBreadcrumbs && (
        <Breadcrumbs aria-label="breadcrumbs" extraClasses="hcr-space-2-bottom">
          <BreadcrumbsItem RouterLink={RouterLink} to="/">
            Home
          </BreadcrumbsItem>
          <BreadcrumbsItem RouterLink={RouterLink} to="/flows">
            Flows
          </BreadcrumbsItem>
        </Breadcrumbs>
      )}
      <Grid>
        <GridCol size={12}>
          <Heading flushSpace size={props.isRequiredForHomePage ? 2 : 1} extraClasses={styles['flow-heading']}>
            Flows
          </Heading>
        </GridCol>
        <GridCol>
          <Text extraClasses={'hcr-space-flush-bottom'}>
            Flows reflect your business cases. They involve one or multiple APIs. There is a dedicated documentation per
            Flow and also example code.
          </Text>
        </GridCol>
      </Grid>
    </section>
  );
}

export default FlowsHeader;
