import { Box, Heading } from '@flixbus/honeycomb-react';

import Change from './Change';

function ChangelogEntries(props) {
  return (
    <>
      {props.changelogs.map((changelogEntry) => {
        return (
          <Box key={changelogEntry.version}>
            <Heading flushSpace size={2}>
              [{changelogEntry.version}] - {changelogEntry.date}
            </Heading>
            {Object.entries(changelogEntry).map(([changeType, changes]) => {
              if (isChange(changeType)) {
                return <Change key={changeType} changeType={changeType} changes={changes}></Change>;
              }
              return undefined;
            })}
          </Box>
        );
      })}
    </>
  );
}

function isChange(change) {
  return ['added', 'changed', 'deprecated', 'fixed', 'removed', 'security'].includes(change);
}

export default ChangelogEntries;
