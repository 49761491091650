import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { RedocStandalone } from 'redoc';

import useApiService from '../../hooks/useApiService';
import { refineApiSpec } from '../../utils/openAPIUtils';
import { addRedoclyExtensions } from '../../utils/redoclyUtils';
import './ApiReference.css';
import ApiReferenceHeader from './ApiReferenceHeader';
import './Redoc.css';
import { getRedocFlixTheme } from './redocTheme';

function ApiReference(props) {
  const { id } = useParams();
  const { getApi } = useApiService();

  const { data: response, error, isError, isLoading } = useQuery([`api${id}`], () => getApi(id));

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>API could not be loaded: {error.message}</div>;
  }

  let apiSpec = addRedoclyExtensions(response.data.apiSpec);

  return (
    <div className={'api-reference'}>
      <ApiReferenceHeader apiName={response.data.name} />
      <div className="redoc">
        <RedocStandalone
          spec={refineApiSpec(apiSpec)}
          options={{
            nativeScrollbars: true,
            theme: getRedocFlixTheme(props.theme),
            scrollYOffset: 90,
          }}
        />
      </div>
    </div>
  );
}

export default ApiReference;
