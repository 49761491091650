import { isMethod } from './httpUtils';

export function createApiOverview(api) {
  let overview = {};

  for (let [pathName, path] of Object.entries(api.apiSpec.paths)) {
    for (let [methodName, method] of Object.entries(path)) {
      if (isMethod(methodName) && method !== null) {
        const isDeprecated = method.deprecated !== undefined ? method.deprecated : false;
        if (method.tags === null || method.tags === undefined) {
          method.tags = ['ungrouped'];
        }
        method.tags.forEach((tag) => {
          if (overview[tag] === undefined) {
            overview[tag] = [];
          }
          if (method.operationId !== undefined) {
            overview[tag].push({ operationId: method.operationId, isDeprecated: isDeprecated });
          } else {
            // Workaround of combination method and path to be used as a label
            overview[tag].push({ operationId: `${methodName} ${pathName}`, isDeprecated: isDeprecated });
          }
        });
      }
    }
  }

  return overview;
}
