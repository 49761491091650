import { Grid, GridCol, Skeleton, Text } from '@flixbus/honeycomb-react';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';

import useFlowService from '../../hooks/useFlowService';
import Flow from './Flow';
import styles from './FlowsList.module.css';

function FlowsList(props) {
  const { getAllFlows } = useFlowService();
  const { data: response, error, isError, isLoading } = useQuery(['flows'], () => getAllFlows());

  if (isLoading) {
    return (
      <div className={props.isRequiredForHomePage ? styles['flows-list-home-page'] : styles['flows-list']}>
        <Grid align="center" gutterSize={'2'}>
          {[...Array(4)].map((_, index) => {
            return (
              <GridCol key={index} size={6} extraClasses="flows">
                <Skeleton Elem="div" height="lg" extraClasses={styles['flow-skeleton']} />
              </GridCol>
            );
          })}
        </Grid>
      </div>
    );
  }

  if (isError) {
    return (
      <div className={props.isRequiredForHomePage ? styles['flows-list-home-page'] : styles['flows-list']}>
        <Text>Flows could not be loaded: {error.message}</Text>
      </div>
    );
  }

  return (
    <div className={props.isRequiredForHomePage ? styles['flows-list-home-page'] : styles['flows-list']}>
      <Grid align="center" gutterSize={'2'}>
        {response.data.map((flow) => {
          return (
            <GridCol key={flow.id} size={12} sm={6} xl={6} extraClasses="flows hcr-space-2-bottom">
              <Flow data={flow} />
            </GridCol>
          );
        })}
      </Grid>
    </div>
  );
}

export default FlowsList;
