import useApiClient from './useApiClient';

function useApiService() {
  const { apiClient } = useApiClient();

  const getAllApis = () => {
    return apiClient()
      .then((client) => client.get('/apis'))
      .then((response) => {
        // Sort the response data by 'id' before returning it
        return {
          ...response,
          data: response.data.sort((a, b) => a.id.localeCompare(b.id)),
        };
      });
  };

  const getApis = async (searchQuery) => {
    let apis = await apiClient().then((client) => client.get(`/apis?q=${searchQuery}`));
    apis.data.map((api) => (api.type = 'api'));
    return apis;
  };

  const getApi = (id) => {
    return apiClient().then((client) => client.get(`/apis/${id}`));
  };

  const getChangelogByApi = (id) => {
    return apiClient().then((client) => client.get(`/apis/${id}/changelogs`));
  };

  const getExamplesByApi = (id) => {
    return apiClient().then((client) => client.get(`/apis/${id}/examples`));
  };

  const getOperations = async (apiId, searchQuery) => {
    let operations = await apiClient().then((client) => client.get(`/apis/${apiId}/operations?q=${searchQuery}`));
    operations.data.map((operation) => (operation.parent = apiId));
    operations.data.map((operation) => (operation.type = 'operation'));
    return operations;
  };

  const getNamedApis = async (apiPreviews) => {
    let apis = [];
    for (const preview of apiPreviews) {
      let api = await getApi(preview.id);
      apis.push(api.data);
    }
    return apis;
  };

  return {
    getAllApis: getAllApis,
    getApis: getApis,
    getApi: getApi,
    getChangelogByApi: getChangelogByApi,
    getExamplesByApi: getExamplesByApi,
    getOperations: getOperations,
    getNamedApis: getNamedApis,
  };
}

export default useApiService;
