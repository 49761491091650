import { Grid, GridCol } from '@flixbus/honeycomb-react';
import * as React from 'react';

import FlowsHeader from './FlowsHeader';
import FlowsList from './FlowsList';
import styles from './FlowsOverview.module.css';

function FlowsOverview(props) {
  return (
    <Grid extraClasses={styles['flows-overview']}>
      <GridCol>
        <FlowsHeader isRequiredForHomePage={props.isRequiredForHomePage} withBreadcrumbs={props.withBreadcrumbs} />
        <FlowsList isRequiredForHomePage={props.isRequiredForHomePage} />
      </GridCol>
    </Grid>
  );
}

export default FlowsOverview;
