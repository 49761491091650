// eslint-disable-default-case
import { isMethod } from './httpUtils';

const OpenAPISnippet = require('openapi-snippet');

const REDOCLY_TAG_GROUPS_EXTENSION = 'x-tagGroups';

const CODE_SNIPPETS_EXTENSION = 'x-codeSamples';
const CODE_SNIPPET_LANGUAGES = ['shell', 'java', 'go', 'node', 'python', 'php', 'csharp'];

/**
 * We are using redocly in order to render API reference pages
 * in the Developer Portal.
 * In order to show API operations, schemas and code snippets we
 * need to add certain extensions redocly supports.
 *
 * We add
 * <ul>
 *     <li>"x-tagGroups" in order to group operations and schemas</li>
 *     <li>and a special type of "tags" in order to show schemas with examples</li>
 *     <li>"x-codeSamples" in order to show code snippets</li>
 * </ul>
 *
 * @param spec
 * @returns {*}
 */
export function addRedoclyExtensions(spec) {
  const operationTags = getOperationTags(spec);
  let tagGroups = [{ name: 'Operations', tags: operationTags }];

  const schemaNames = getSchemaNames(spec.components);
  if (schemaNames.length > 0) {
    tagGroups.push({ name: 'Schemas', tags: schemaNames });
    spec.tags = getSchemaTags(schemaNames, spec);
  }

  spec[REDOCLY_TAG_GROUPS_EXTENSION] = tagGroups;

  const specWithCodeSnippets = addCodeSnippets(spec);
  const spectWithCustomFields = addCustomFields(specWithCodeSnippets);

  return spectWithCustomFields;
}

function getSchemaNames(components) {
  const schemaNames = [];

  for (let [schemaName] of Object.entries(components.schemas)) {
    schemaNames.push(schemaName);
  }

  return [...new Set(schemaNames)].sort();
}

/**
 * Returns an array of redocly-specific tag objects of the following
 * format:
 *
 * {
 *  "name": "$SCHEMA_NAME",
 *  "x-displayName": "$SCHEMA_NAME",
 *  "description": "<SchemaDefinition schemaRef=\"#/components/schemas/$SCHEMA_NAME\" exampleRef=\"#/components/examples/$SCHEMA_NAMEExample\"/>"
 * }
 *
 * @param schemaNames
 * @param spec
 * @returns {*[]}
 */
function getSchemaTags(schemaNames, spec) {
  const schemaTags = [];
  let description;

  schemaNames.forEach((name) => {
    if (spec.components.examples !== undefined && spec.components.examples[`${name}Example`] !== undefined) {
      description = `<SchemaDefinition schemaRef="#/components/schemas/${name}" exampleRef="#/components/examples/${name}Example"/>`;
    } else {
      description = `<SchemaDefinition schemaRef="#/components/schemas/${name}"/>`;
    }
    schemaTags.push({ name: name, 'x-displayName': name, description: description });
  });

  return schemaTags;
}

function getOperationTags(spec) {
  let tags = ['ungrouped'];
  for (let [pathName, pathObject] of Object.entries(spec.paths)) {
    if (pathName.includes('/')) {
      for (let [methodName, methodObject] of Object.entries(pathObject)) {
        if (isMethod(methodName)) {
          if (methodObject.tags !== undefined) {
            tags = tags.concat(methodObject.tags);
          }
        }
      }
    }
  }
  return [...new Set(tags)];
}

export function addCodeSnippets(spec) {
  for (let [pathName, pathObject] of Object.entries(spec.paths)) {
    if (pathName.includes('/')) {
      for (let [methodName, methodObject] of Object.entries(pathObject)) {
        if (isMethod(methodName)) {
          let snippets = getCodeSnippets(spec, pathName, methodName);
          methodObject[CODE_SNIPPETS_EXTENSION] = snippets;
        }
      }
    }
  }

  return spec;
}

/**
 * Returns an array of code snippets for redocly,
 * which have the following properties:
 * <ul>
 *     <li>lang</li>
 *     <li>label</li>
 *     <li>source</li>
 * </ul>
 *
 * More infos can be found here:
 * https://redocly.com/docs/api-reference-docs/specification-extensions/x-code-samples/
 */
function getCodeSnippets(openApi, path, method) {
  let codeSnippets = [];
  try {
    const results = OpenAPISnippet.getEndpointSnippets(openApi, path, method, CODE_SNIPPET_LANGUAGES);
    results.snippets.forEach((snippet) =>
      codeSnippets.push({ lang: snippet.title, label: mapLabel(snippet.title), source: snippet.content })
    );
  } catch (err) {
    console.log('code snippet generator failed due to: ' + err);
  } finally {
    return codeSnippets;
  }
}

function mapLabel(title) {
  title = title.toLowerCase().split(' ')[0];

  switch (title) {
    case 'shell':
      return 'curl';
    case 'java':
      return 'Java';
    case 'go':
      return 'Go';
    case 'node':
      return 'Node.js';
    case 'php':
      return 'PHP';
    case 'python':
      return 'Python';
    default:
      return 'C#';
  }
}

function addCustomFields(jsonData) {
  try {
    // Check if the JSON has a "paths" object
    if (!jsonData.hasOwnProperty('paths')) {
      return jsonData; // Return the JSON as it is if there are no paths
    }
    // Iterate through each path and method and add the "servers" data
    for (const path in jsonData.paths) {
      if (jsonData.paths.hasOwnProperty(path)) {
        const pathData = jsonData.paths[path];
        for (const method in pathData) {
          if (isMethod(method)) {
            if (pathData.hasOwnProperty(method)) {
              const methodData = pathData[method];

              if (methodData.tags === undefined) {
                // Set ungrouped as tags are needed to be rendered by Redoc
                methodData.tags = ['ungrouped'];
              }

              if (methodData.operationId === undefined) {
                // Workaround of assigning operationId the combination of method and path
                methodData.operationId = `${method} ${path}`;
              }

              const extensions = methodData['extensions'];
              if (extensions !== undefined && extensions['x-amazon-apigateway-integration']) {
                // Use the "uri" value from "x-amazon-apigateway-integration" in "extensions" as the "url" for Upstream
                const uri = extensions['x-amazon-apigateway-integration'].uri;
                // Added verification to avoid double adding of upstream info
                if (methodData.description !== undefined && !methodData.description.includes(uri)) {
                  methodData.description = `>__Production upstream endpoint:__ ${uri}\n\n${methodData.description}`;
                } else {
                  methodData.description = `>__Production upstream endpoint:__ ${uri}`;
                }
              }
            }
          }
        }
      }
    }
    return jsonData;
  } catch (err) {
    console.log('addition of custom fields failed due to: ' + err);
  }
}
