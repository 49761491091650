import { Breadcrumbs, BreadcrumbsItem, Grid, GridCol, Heading, Text } from '@flixbus/honeycomb-react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import styles from './ApisHeader.module.css';

function ApisHeader(props) {
  return (
    <section className={props.isRequiredForHomePage ? styles['apis-header-home-page'] : styles['apis-header']}>
      {props.withBreadcrumbs && (
        <>
          <Breadcrumbs aria-label="breadcrumbs" extraClasses="hcr-space-2-bottom">
            <BreadcrumbsItem RouterLink={RouterLink} to="/">
              Home
            </BreadcrumbsItem>
            <BreadcrumbsItem RouterLink={RouterLink} to="/apis">
              APIs
            </BreadcrumbsItem>
          </Breadcrumbs>
        </>
      )}
      <Grid>
        <GridCol size={12}>
          <Heading flushSpace size={props.isRequiredForHomePage ? 2 : 1} extraClasses={styles['apis-heading']}>
            APIs
          </Heading>
        </GridCol>
        <GridCol>
          <Text extraClasses="hcr-space-flush-bottom">
            With our powerful APIs you can programmatically work with Flix assets like ancillaries, payments and
            timetables. There is a dedicated documentation per API and also example code.
          </Text>
        </GridCol>
      </Grid>
    </section>
  );
}

export default ApisHeader;
