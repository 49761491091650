import { Grid, GridCol, Text } from '@flixbus/honeycomb-react';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import useApiService from '../../../hooks/useApiService';
import { getAPINameFromId } from '../../../utils/wordUtils';
import styles from '../Basics.module.css';
import BasicsHeader from '../BasicsHeader';
import SecondaryNav from '../SecondaryNav';
import ChangelogEntries from './ChangelogEntries';

function Changelog() {
  const { id } = useParams();
  const apiName = getAPINameFromId(id);

  const { getChangelogByApi } = useApiService();
  const {
    data: response,
    error,
    isError,
    isLoading,
  } = useQuery([`changelog${id}`], () => getChangelogByApi(id), {
    retry: (failureCount, { response }) => {
      return response.status !== 404;
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    if (error.message.includes('404')) {
      return (
        <Grid gutterSize={'2'} extraClasses={styles['getting-started-container']}>
          <GridCol size={2} extraClasses={styles['basics-api-secondary-nav']}>
            <SecondaryNav />
          </GridCol>
          <GridCol size={10} extraClasses={styles['basics-content']}>
            <BasicsHeader
              breadcrumbs={[
                { name: 'Home', link: '/' },
                { name: 'APIs', link: '/apis' },
                { name: apiName, link: `/apis/${id}` },
                { name: 'Changelog', link: `/apis/${id}/changelog` },
              ]}
              title="Changelog"
              subtitle="Details on the history of this API"
              resourceType="api"
            />
            <Text>This API does not provide a changelog yet.</Text>
          </GridCol>
        </Grid>
      );
    }
    return <div>Changelogs could not be loaded: {error.message}</div>;
  }

  return (
    <>
      <Grid gutterSize={'2'} extraClasses={styles['getting-started-container']}>
        <GridCol size={2} extraClasses={styles['basics-api-secondary-nav']}>
          <SecondaryNav />
        </GridCol>
        <GridCol size={10} extraClasses={styles['basics-content']}>
          <BasicsHeader
            breadcrumbs={[
              { name: 'Home', link: '/' },
              { name: 'APIs', link: '/apis' },
              { name: apiName, link: `/apis/${id}` },
              { name: 'Changelog', link: `/apis/${id}/changelog` },
            ]}
            title="Changelog"
            subtitle="Details on the history of this API"
          />
          <ChangelogEntries changelogs={response.data} />
        </GridCol>
      </Grid>
    </>
  );
}

export default Changelog;
