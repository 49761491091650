import useApiClient from './useApiClient';

const useCollectionService = () => {
  const { apiClient } = useApiClient();
  const getCollection = (id) => {
    return apiClient().then((client) => client.get(`/collections/${id}`));
  };

  return {
    getCollection: getCollection,
  };
};

export default useCollectionService;
