import { Grid, GridCol, Text } from '@flixbus/honeycomb-react';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';

import useCollectionService from './useCollectionService';

export default function useCollection(id) {
  const { getCollection } = useCollectionService();
  const { data: response, error, isError, isLoading } = useQuery([`collection${id}`], () => getCollection(id));

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    if (error.message.includes('404')) {
      return (
        <Grid align={'center'} justify={'center'}>
          <GridCol size={4} extraClasses="hcr-has-text-centered hcr-space-8-top">
            <Text>Collection couldn't be found</Text>
          </GridCol>
        </Grid>
      );
    }
    return <div>Collection could not be loaded: {error.message}</div>;
  }

  return response.data;
}
