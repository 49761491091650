import { Heading, NavItem, NavSide } from '@flixbus/honeycomb-react';
import * as React from 'react';

function ServiceList(props) {
  return (
    <>
      <Heading size={3}>{props.title}</Heading>
      <NavSide key={props.title} aria-label="Endpoint links">
        {props.endpoints.map((endpoint) => (
          <NavItem key={endpoint.operationId} href={`/apis/${props.id}/reference#operation/${endpoint.operationId}`}>
            {endpoint.operationId}
          </NavItem>
        ))}
      </NavSide>
    </>
  );
}

export default ServiceList;
