import { Grid, GridCol, Skeleton, Text } from '@flixbus/honeycomb-react';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';

import useApiService from '../../hooks/useApiService';
import Api from './Api';
import styles from './ApisList.module.css';

function ApisList(props) {
  const { getAllApis } = useApiService();
  const { data: response, error, isError, isLoading } = useQuery(['apis'], () => getAllApis());

  if (isLoading) {
    return (
      <div className={props.isRequiredForHomePage ? styles['apis-list-home-page'] : styles['apis-list']}>
        <Grid align="center" gutterSize="2">
          {[...Array(12)].map((_, index) => {
            return (
              <GridCol key={index} size={6} xl={4} extraClasses="apis hcr-space-2-bottom">
                <Skeleton Elem="div" height="lg" extraClasses={styles['api-skeleton']} />
              </GridCol>
            );
          })}
        </Grid>
      </div>
    );
  }

  if (isError) {
    return (
      <div className={props.isRequiredForHomePage ? styles['apis-list-home-page'] : styles['apis-list']}>
        <Text>APIs could not be loaded: {error.message}</Text>
      </div>
    );
  }

  return (
    <div className={props.isRequiredForHomePage ? styles['apis-list-home-page'] : styles['apis-list']}>
      <Grid align="center" gutterSize="2">
        {response.data.map((api) => {
          return (
            <GridCol key={api.id} size={12} sm={6} xl={4} extraClasses="apis hcr-space-2-bottom">
              <Api data={api} />
            </GridCol>
          );
        })}
      </Grid>
    </div>
  );
}

export default ApisList;
