import yaml from 'js-yaml';
import * as React from 'react';
import { RedocStandalone } from 'redoc';

import { addRedoclyExtensions } from '../../utils/redoclyUtils';
import { getRedocFlixTheme } from '../apis/redocTheme';
import './APIReferenceRenderer.css';

function APIReferenceRenderer(props) {
  const apiSpecYaml = window.localStorage.getItem('swagger-editor-content');
  const apiSpecJson = yaml.load(apiSpecYaml);
  let apiSpec = addRedoclyExtensions(apiSpecJson);
  return (
    <div className="redoc">
      <RedocStandalone
        spec={apiSpec}
        options={{
          nativeScrollbars: true,
          theme: getRedocFlixTheme(props.theme),
          scrollYOffset: 90,
        }}
      />
    </div>
  );
}

export default APIReferenceRenderer;
