import { Fineprint, Grid, GridCol, Link, Text } from '@flixbus/honeycomb-react';

import Header from './Header';
import styles from './Legal.module.css';

function Contact() {
  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Home', link: '/' },
          { name: 'Contact', link: '/contact' },
        ]}
        title="Contact"
      />
      <main>
        <Grid>
          <GridCol size={4} extraClasses="hcr-space-4-bottom">
            <Text>
              <span className={styles.columnHeader}>Address</span>
              <br />
              Flix SE
              <br />
              Friedenheimer Brücke 16
              <br />
              80639 Munich
              <br />
              Germany
            </Text>
          </GridCol>
          <GridCol size={4} extraClasses="hcr-space-4-bottom">
            <Text>
              <span className={styles.columnHeader}>Phone</span>
              <br />
              +49 (0)30 300 137 300.*
              <br />
              <span className={styles.callRateDisclaimer}>
                <Fineprint>
                  * (Local calls from German landlines. Rates may vary for calls from mobile phones.)
                </Fineprint>
              </span>
            </Text>
          </GridCol>
          <GridCol size={4} extraClasses="hcr-space-4-bottom">
            <Text>
              <span className={styles.columnHeader}>E-mail</span>
              <br />
              <Link href="mailto:impressum@flixbus.de">impressum@flixbus.de</Link>
            </Text>
          </GridCol>
        </Grid>
      </main>
    </>
  );
}

export default Contact;
