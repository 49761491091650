import { Heading, ListWrapper, ListWrapperItem, Tag, Text } from '@flixbus/honeycomb-react';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import './ApiOverview.css';

function Service(props) {
  const { id } = useParams();
  const deprecatedTag = (
    <Tag small appearance="warning" extraClasses="hcr-space-1-left" display="outlined">
      Deprecated
    </Tag>
  );

  return (
    <>
      <Heading key={props.title} size={4}>
        {props.title}
      </Heading>
      <ListWrapper small extraClasses="primary-background">
        {props.endpoints.map((endpoint) => {
          return (
            <ListWrapperItem
              key={endpoint.operationId}
              href={`/apis/${id}/reference#operation/${endpoint.operationId}`}
            >
              <Text extraClasses="button-text hcr-space-flush-bottom">{endpoint.operationId}</Text>{' '}
              {endpoint.isDeprecated && deprecatedTag}
            </ListWrapperItem>
          );
        })}
      </ListWrapper>
    </>
  );
}

export default Service;
