import {
  Icon,
  IconChatSolid,
  IconCheckmarkStrongSolid,
  IconDeleteSolid,
  IconOfferSolid,
  IconRefresh,
  IconSecuritySolid,
} from '@flixbus/honeycomb-icons-react';
import { List, Text } from '@flixbus/honeycomb-react';

import { capitalize } from '../../../utils/wordUtils';
import styles from './Change.module.css';

function Change(props) {
  const icon = getChangeIcon(props.changeType);

  return (
    <>
      <Icon InlineIcon={icon.icon} style={icon.style} size={3} />{' '}
      <Text extraClasses={styles['change-type']}>{capitalize(props.changeType)}</Text>
      <List extraClasses="hcr-space-flush-bottom">
        {props.changes.map((change) => (
          <li>{change}</li>
        ))}
      </List>
    </>
  );
}

function getChangeIcon(change) {
  switch (change) {
    case 'added':
      return { icon: IconOfferSolid, style: { fill: 'var(--hcr-neutral-color)' } };
    case 'changed':
      return { icon: IconRefresh, style: { fill: 'var(--hcr-neutral-color)' } };
    case 'deprecated':
      return { icon: IconChatSolid, style: { fill: 'var(--hcr-neutral-color)' } };
    case 'fixed':
      return { icon: IconCheckmarkStrongSolid, style: { fill: 'var(--hcr-success-color)' } };
    case 'removed':
      return { icon: IconDeleteSolid, style: { fill: 'var(--hcr-danger-color)' } };
    case 'security':
      return { icon: IconSecuritySolid, style: { fill: 'var(--hcr-neutral-color)' } };
    default:
      return { icon: IconOfferSolid, style: { fill: 'var(--hcr-neutral-color)' } };
  }
}

export default Change;
