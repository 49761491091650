import { Icon, IconNewTab } from '@flixbus/honeycomb-icons-react';
import { Link, Text } from '@flixbus/honeycomb-react';
import * as React from 'react';

import styles from '../Basics.module.css';
import CollectionDownloadButton from './CollectionDownloadButton';
import CollectionViewButton from './CollectionViewButton';

function Artifact(props) {
  const isLinkForExternals = props.artifact.link.includes('flixbus.com');

  return (
    <>
      {
        /* If technology is postman return a text and not a link */
        props.isPostman ? (
          <Text extraClasses={styles['basics-example-postman-title']}>{props.artifact.title}</Text>
        ) : (
          <Text>
            <Link target="_blank" href={props.artifact.link}>
              {props.artifact.title}
              <Icon style={{ fill: 'currentColor' }} InlineIcon={IconNewTab} aria-label="Opens in a New Tab" />
            </Link>
          </Text>
        )
      }
      <Text>{props.artifact.description}</Text>
      {
        /* Postman examples will have a download button */
        props.isPostman &&
          ((isLinkForExternals && <CollectionDownloadButton link={props.artifact.link} />) || (
            <CollectionViewButton link={props.artifact.link} />
          ))
      }
    </>
  );
}

export default Artifact;
