import { Icon, IconNewTab } from '@flixbus/honeycomb-icons-react';
import { Divider, FooterNav, NavItem, PageContainer, Switch, Text } from '@flixbus/honeycomb-react';
import * as React from 'react';

import useGlobalHelpLink from '../../hooks/useGlobalHelpLink';
import './Footer.css';

function Footer(props) {
  const globalHelpLink = useGlobalHelpLink();
  return (
    <div className="footer">
      <footer>
        <Divider />
        <PageContainer extraClasses="footer__container">
          <FooterNav extraClasses="footer__nav" horizontal>
            <NavItem href="/imprint">Imprint</NavItem>
            <NavItem href="/contact">Contact</NavItem>
            <NavItem href="/design">Design</NavItem>
            <NavItem href={globalHelpLink}>
              Help <Icon style={{ fill: 'currentColor' }} InlineIcon={IconNewTab} aria-label="Opens in a New Tab" />
            </NavItem>
            <NavItem href="/faq">FAQ</NavItem>
            <NavItem extraClasses="footer__copyright">
              <Text extraClasses="footer__copyright" Elem="small">
                © 2022 Flix SE
              </Text>
            </NavItem>
            <NavItem>
              <Switch
                extraClasses="footer__switchers"
                small
                label="Dark mode"
                id="theme-wrapper-theme-switch"
                value=""
                onChange={props.themeToggleHandler}
                defaultChecked={props.theme !== 'flix'}
              />
            </NavItem>
          </FooterNav>
        </PageContainer>
      </footer>
    </div>
  );
}

export default Footer;
