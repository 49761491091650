import { Grid, GridCol, Text } from '@flixbus/honeycomb-react';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import useFlowService from '../../../hooks/useFlowService';
import ArtifactList from './ArtifactList';
import styles from './FlowGettingStarted.module.css';

function FlowGettingStarted(props) {
  const { id } = useParams();

  const missingGettingStartedMessage = <Text>This Flow does not provide getting started resources yet.</Text>;
  const { getExamplesByFlow } = useFlowService();

  const {
    data: response,
    error,
    isError,
    isLoading,
  } = useQuery([`flow-getting-started-${id}`], () => getExamplesByFlow(id));

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    if (error.message.includes('404')) {
      return (
        <Grid align={'center'} justify={'center'}>
          <GridCol size={4} extraClasses="hcr-has-text-centered hcr-space-8-top">
            <Text>This Flow does not provide examples yet.</Text>
          </GridCol>
        </Grid>
      );
    }
    return <div>Examples could not be loaded: {error.message}</div>;
  }

  return (
    <section className={styles['getting-started__container']}>
      {response.data.length === 0
        ? missingGettingStartedMessage
        : response.data.map((artifactList) => {
            return <ArtifactList key={artifactList.technology} artifactList={artifactList} />;
          })}
    </section>
  );
}

export default FlowGettingStarted;
