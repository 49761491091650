import { Infobox } from '@flixbus/honeycomb-react';

const MarkdownRenderer = ({ node, inline, className, children, ...props }) => {
  const match = /language-x-fxt-api-flow-description-(\w+)/.exec(className || '');
  let type = 'warning';

  if (className && className.includes('-')) {
    if (className === 'language-x-fxt-api-flow-description-info') {
      type = undefined;
    } else {
      type = className.split('-').pop();
    }
  }

  return match ? (
    <Infobox appearance={type || undefined} extraClasses="flow-description-info-box">
      {children}
    </Infobox>
  ) : (
    <code className={className} {...props}>
      {children}
    </code>
  );
};

export default MarkdownRenderer;
