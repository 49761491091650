import { Icon, IconNewTab } from '@flixbus/honeycomb-icons-react';
import { Link, Text } from '@flixbus/honeycomb-react';

import useGlobalHelpLink from '../../hooks/useGlobalHelpLink';
import Header from '../legal/Header';

function Faq() {
  const globalHelpLink = useGlobalHelpLink();
  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Home', link: '/' },
          { name: 'FAQ', link: '/faq' },
        ]}
        title="FAQ"
      />
      <main>
        <Text>
          <h3>What is the purpose of this Flix Developer Portal?</h3>
          The Flix Developer Portal serves as a central hub for API consumers to access documentation, resources, and
          tools related to Flix APIs and Flows. It's designed to facilitate seamless integration and collaboration
          between developers and our services.
          <br />
          <h3>What can I find in the API section?</h3>
          You'll discover a list of APIs, each with its operations and detailed usage information for each operation.
          <br />
          <h3>What can I find in the Flows section?</h3>
          A Flow is a set of API requests for a business case. Each Flow has a clear goal and can use one or more APIs.
          The Developer Portal provides specific documentation and code examples for each Flow.
          <br />
          <h3>Can I see the last changes of an API?</h3>
          You can access the most recent changes for an API by checking its changelog, if it is provided by the API
          provider. Additionally, you have the option to subscribe and receive updates about the latest changes by
          email.
          <br />
          <h3>Where can I report an issue or inquire about the API documentation?</h3>
          Look for the link Get Help on each API in the top right corner to request support related to the API.
          <br />
          <h3>Where can I report an issue related to the Flix Developer Portal itself?</h3>
          You can use this link:{' '}
          <Link href={globalHelpLink}>
            Help <Icon style={{ fill: 'currentColor' }} InlineIcon={IconNewTab} aria-label="Opens in a New Tab" />
          </Link>{' '}
          to report any issue related to our Flix Developer Portal. You can also find this in the footer section of
          every page.
          <br />
        </Text>
      </main>
    </>
  );
}

export default Faq;
