export function getAPINameFromId(id) {
  let nameFragments = id.split('-');
  return nameFragments
    .map((fragment, index, fragmentsArray) => {
      if (index + 1 === fragmentsArray.length) {
        return fragment.toUpperCase();
      }
      return fragment[0].toUpperCase() + fragment.substring(1);
    })
    .join(' ');
}

export function getFlowNameFromId(id) {
  let nameFragments = id.split('-');
  return nameFragments
    .map((fragment) => {
      return fragment[0].toUpperCase() + fragment.substring(1);
    })
    .join(' ');
}

export function capitalize(word) {
  return word[0].toUpperCase() + word.substring(1);
}

export function getAPIDescription(description) {
  if (description !== undefined) {
    return description.split('\n')[0];
  } else {
    return '';
  }
}
