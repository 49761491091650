import { Grid, GridCol, Heading, Text } from '@flixbus/honeycomb-react';

import { ReactComponent as FlixDevMaintenanceLogo } from '../../assets/img/flixDevMaintenanceLogo.svg';
import ApisOverview from '../apis/ApisOverview';
import FlowsOverview from '../flows/FlowsOverview';
import './Home.css';

function Home() {
  return (
    <>
      <Grid applyContainer={true} align="center" extraClasses="hero-section">
        <GridCol size={2} extraClasses="hero-section-logo">
          <FlixDevMaintenanceLogo />
        </GridCol>
        <GridCol size={10} extraClasses="hero-section-description">
          <Heading flushSpace size={1} sectionHeader={true}>
            Welcome to the Flix Developer Portal
          </Heading>
          <Text>All about how to integrate Flix APIs on your side.</Text>
        </GridCol>
      </Grid>
      <section className={'home-content'}>
        <FlowsOverview isRequiredForHomePage={true} />
        <ApisOverview isRequiredForHomePage={true} />
      </section>
    </>
  );
}

export default Home;
