import { Grid, GridCol, Link, Text } from '@flixbus/honeycomb-react';

import Header from './Header';
import styles from './Legal.module.css';

function Imprint() {
  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Home', link: '/' },
          { name: 'Imprint', link: '/imprint' },
        ]}
        title="Imprint"
      />
      <main>
        <Grid>
          <GridCol size={4} extraClasses="hcr-space-4-bottom">
            <Text>
              <span className={styles.columnHeader}>Chairman of supervisory board</span>
              <br />
              Achim Berg
              <br />
            </Text>
            <Text>
              <span className={styles.columnHeader}>Board Members</span>
              <br />
              André Schwämmlein (Chairman), Daniel Krauss, Jean Christoph Debus
            </Text>
          </GridCol>
          <GridCol size={4} extraClasses="hcr-space-4-bottom">
            <Text>
              <span className={styles.columnHeader}>Issuer</span>
              <br />
              Flix SE
            </Text>
          </GridCol>
          <GridCol size={4} extraClasses="hcr-space-4-bottom">
            <Text>
              <span className={styles.columnHeader}>Commercial register:</span>
              <br />
              District court of Munich, HRB 274912
            </Text>
          </GridCol>
          <GridCol size={4} extraClasses="hcr-space-4-bottom">
            <Text>
              <span className={styles.columnHeader}>Responsible according to § 18 par. 2 MStV</span>
              <br />
              Daniel Krauss
              <br />
              Friedenheimer Brücke 16
              <br />
              80639 München
              <br />
              E-Mail: <Link href="mailto:impressum@flixbus.de">impressum@flixbus.de</Link>
            </Text>
          </GridCol>
          <GridCol size={4} extraClasses="hcr-space-4-bottom">
            <Text>
              <span className={styles.columnHeader}>VAT ID:</span>
              <br />
              DE 283764680
            </Text>
          </GridCol>
          <GridCol size={12} extraClasses="hcr-space-4-bottom">
            <Text>
              <span className={styles.columnHeader}>Liability for content</span>
              <br />
              Our webpage content has been compiled with utter care. We can not take any responsibility for the
              correctness, completeness and topicality of contents. As a service provider we are responsible according
              to § 7 paragraph 1 TMG for our own contents on these pages under the general laws. According to §§ 8 to 10
              TMG we are not obliged as a service provider to monitor transmitted or stored foreign information or to
              investigate circumstances that indicate illegal activity. Obligations to remove or block the use of
              information under the general laws remain unaffected. However, relevant liability is only possible from
              the date of knowledge of a specific infringement. Upon notification of such violations, we will remove the
              content immediately.
            </Text>
          </GridCol>
          <GridCol size={12} extraClasses="hcr-space-4-bottom">
            <Text>
              The European Commission provides a platform (ODR platform) for online dispute resolution without the need
              to go to court. It is available at{' '}
              <Link target="_blank" href="https://www.ec.europa.eu/consumers/odr">
                www.ec.europa.eu/consumers/odr
              </Link>
              . We are part of a dispute resolution procedure before a consumer arbitration board. A list with the
              contact information of approved dispute resolution bodies can be found at{' '}
              <Link target="_blank" href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.adr.show">
                https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.adr.show
              </Link>
              . In this regard, please refer to the Schlichtungsstelle öffentlicher Personenverkehr e.V. [German
              Conciliation Body for Public Transport] (
              <Link target="_blank" href="https://www.soep-online.de">
                www.soep-online.de
              </Link>
              ).
            </Text>
          </GridCol>
        </Grid>
      </main>
    </>
  );
}

export default Imprint;
