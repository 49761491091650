import { Grid, GridCol, Text } from '@flixbus/honeycomb-react';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import useApiService from '../../../hooks/useApiService';
import { getAPINameFromId } from '../../../utils/wordUtils';
import styles from '../Basics.module.css';
import BasicsHeader from '../BasicsHeader';
import SecondaryNav from '../SecondaryNav';
import ArtifactList from './ArtifactList';

function APIGettingStarted() {
  const { id } = useParams();
  const apiName = getAPINameFromId(id);

  const missingGettingStartedMessage = <Text>This API does not provide getting started resources yet.</Text>;
  const { getExamplesByApi } = useApiService();

  const {
    data: response,
    error,
    isError,
    isLoading,
  } = useQuery([`api-getting-started-${id}`], () => getExamplesByApi(id));

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Examples could not be loaded: {error.message}</div>;
  }

  return (
    <Grid gutterSize={'2'} extraClasses={styles['getting-started-container']}>
      <GridCol size={2} extraClasses={styles['basics-api-secondary-nav']}>
        <SecondaryNav />
      </GridCol>
      <GridCol size={10} extraClasses={styles['basics-content']}>
        <BasicsHeader
          breadcrumbs={[
            { name: 'Home', link: '/' },
            { name: 'APIs', link: '/apis' },
            { name: apiName, link: `/apis/${id}` },
            { name: 'Getting started', link: `/apis/${id}/getting-started` },
          ]}
          title="Getting started"
          subtitle={`Get started working with the ${apiName}.`}
          resourceType="api"
        />
        {response.data.length === 0
          ? missingGettingStartedMessage
          : response.data.map((artifactList) => {
              return <ArtifactList key={artifactList.technology} artifactList={artifactList} />;
            })}
      </GridCol>
    </Grid>
  );
}

export default APIGettingStarted;
