import { Grid, GridCol } from '@flixbus/honeycomb-react';
import * as React from 'react';

import ApisHeader from './ApisHeader';
import ApisList from './ApisList';
import styles from './ApisOverview.module.css';

function ApisOverview(props) {
  return (
    <Grid extraClasses={styles['apis-overview']}>
      <GridCol>
        <ApisHeader isRequiredForHomePage={props.isRequiredForHomePage} withBreadcrumbs={props.withBreadcrumbs} />
        <ApisList isRequiredForHomePage={props.isRequiredForHomePage} />
      </GridCol>
    </Grid>
  );
}

export default ApisOverview;
