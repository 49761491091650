import { themeFlix, themeFlixDark } from '@flixbus/honeycomb-react';

import './Redoc.css';

function getRedocFlixTheme(currentTheme) {
  const selectedTheme = currentTheme === 'flix-dark' ? themeFlixDark : themeFlix;

  const theme = {
    colors: {
      text: {
        primary: selectedTheme['content-primary-color'],
        secondary: selectedTheme['content-secondary-color'],
      },
      primary: {
        main: selectedTheme['content-primary-color'],
      },
      secondary: {
        main: selectedTheme['content-secondary-color'],
      },
      accent: {
        main: selectedTheme['focus-outline-color'],
      },
      warning: {
        main: selectedTheme['warning-color'],
      },
      error: {
        main: selectedTheme['danger-color'],
      },
      responses: {
        error: {
          color: selectedTheme['danger-color'],
          // 38 corresponds, in hexadecimal, to ff minus e0, e0 corresponds to the alpha value of bg-primary-transparent-color
          backgroundColor: selectedTheme['danger-color'].concat('38'),
        },
      },
      http: {
        get: selectedTheme['brand-primary-color'],
        post: selectedTheme['brand-primary-color'],
        put: selectedTheme['brand-primary-color'],
        options: selectedTheme['brand-primary-color'],
        patch: selectedTheme['brand-primary-color'],
        delete: selectedTheme['brand-primary-color'],
        basic: selectedTheme['brand-primary-color'],
        link: selectedTheme['brand-primary-color'],
        head: selectedTheme['brand-primary-color'],
      },
    },
    typography: {
      fontFamily: selectedTheme['font-family-primary'],
      fontSize: selectedTheme['font-size-primary'],
      fontWeightBold: selectedTheme['font-weight-bold'],
      fontWeightRegular: selectedTheme['font-weight-normal'],
      lineHeight: selectedTheme['line-height-primary'],
      code: {
        fontFamily: "'SF Mono', 'Cascadia Mono', Consolas, 'Ubuntu Mono', 'Liberation Mono', 'Courier New', monospace",
        fontSize: selectedTheme['font-size-small'],
        fontWeight: selectedTheme['font-weight-normal'],
        lineHeight: selectedTheme['line-height-small'],
      },
      headings: {
        fontFamily: selectedTheme['font-family-primary'],
        lineHeight: selectedTheme['line-height-primary'],
      },
      fieldName: {
        fontFamily: "'SF Mono', 'Cascadia Mono', Consolas, 'Ubuntu Mono', 'Liberation Mono', 'Courier New', monospace",
        fontSize: selectedTheme['font-size-small'],
        fontWeight: selectedTheme['font-weight-normal'],
        lineHeight: selectedTheme['line-height-small'],
      },
      links: {
        color: selectedTheme['link-color'],
      },
    },
    sidebar: {
      textColor: selectedTheme['content-primary-color'],
      arrow: {
        color: selectedTheme['icon-primary-color'],
        size: selectedTheme['line-height-primary'],
      },
      width: '285px',
      backgroundColor: selectedTheme['grayscale-10-color'],
      groupItems: {
        textTransform: 'none',
      },
    },
    rightPanel: {
      textColor: selectedTheme['grayscale-30-color'],
      backgroundColor: selectedTheme['grayscale-90-color'],
    },
    codeBlock: {
      backgroundColor: selectedTheme['grayscale-100-color'],
    },
  };

  return theme;
}

export { getRedocFlixTheme };
