import { Breadcrumbs, BreadcrumbsItem, Heading } from '@flixbus/honeycomb-react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import styles from './Header.module.css';

function Header(props) {
  return (
    <header className={styles.hero}>
      <Breadcrumbs aria-label="breadcrumbs" extraClasses="hcr-space-2-bottom">
        {props.breadcrumbs.map((breadcrumbsItem) => {
          return (
            <BreadcrumbsItem key={breadcrumbsItem.name} RouterLink={RouterLink} to={breadcrumbsItem.link}>
              {breadcrumbsItem.name}
            </BreadcrumbsItem>
          );
        })}
      </Breadcrumbs>
      <Heading size={1}>{props.title}</Heading>
    </header>
  );
}

export default Header;
