import { Button } from '@flixbus/honeycomb-react';
import * as FileSaver from 'file-saver';
import * as React from 'react';

import useCollection from '../../../hooks/useCollection';

function CollectionDownloadButton(props) {
  const collection = useCollection(props.link.split('/').pop());

  const downloadCollection = () => {
    const blob = new Blob([JSON.stringify(collection, null, 2)], {
      type: 'application/json',
    });
    FileSaver.saveAs(blob, 'collection.json');
  };

  return <Button onClick={() => downloadCollection()}>Download collection</Button>;
}

export default CollectionDownloadButton;
