import useApiClient from './useApiClient';

const useFlowService = () => {
  const { apiClient } = useApiClient();
  const getAllFlows = () => {
    return apiClient()
      .then((client) => client.get('/flows'))
      .then((response) => {
        // Sort the response data by 'id' before returning it
        return {
          ...response,
          data: response.data.sort((a, b) => a.id.localeCompare(b.id)),
        };
      });
  };

  const getFlows = async (searchQuery) => {
    let flows = await apiClient().then((client) => client.get(`/flows?q=${searchQuery}`));
    flows.data.map((flow) => (flow.type = 'flow'));
    return flows;
  };

  const getFlow = (id) => {
    return apiClient().then((client) => client.get(`/flows/${id}`));
  };

  const getExamplesByFlow = (id) => {
    return apiClient().then((client) => client.get(`/flows/${id}/examples`));
  };

  return {
    getAllFlows: getAllFlows,
    getFlows: getFlows,
    getFlow: getFlow,
    getExamplesByFlow: getExamplesByFlow,
  };
};

export default useFlowService;
