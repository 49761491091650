import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';

import useApiService from '../../hooks/useApiService';
import MarkdownRenderer from '../../utils/MarkdownRenderer';
import { getBasepath, isMethod } from '../../utils/httpUtils';
import FlowStep from './FlowStep';

function FlowOverview(props) {
  const { id } = useParams();
  const { getNamedApis } = useApiService();

  const apis = useQuery([`flowApis${id}`], () => getNamedApis(props.flowApis));

  if (apis.isLoading) {
    return <div>Loading...</div>;
  }

  if (apis.isError) {
    return <div>Data could not be loaded</div>;
  }

  let linkMap = {};
  apis.data.forEach(function (api) {
    const url = new URL(api.apiSpec.servers[0].url);
    for (let [pathName, path] of Object.entries(api.apiSpec.paths)) {
      for (let [methodName, method] of Object.entries(path)) {
        if (isMethod(methodName) && method !== null) {
          let key = `${methodName.toLowerCase()} ${url.pathname}${pathName}`;
          let value = `/apis/${api.id}/reference`;
          if (method.operationId !== undefined) {
            value = `${value}#operation/${method.operationId}`;
          } else {
            // Workaround of combination of method and path is set for the link
            value = `${value}#operation/${methodName} ${pathName}`;
          }
          linkMap[key] = value;
        }
      }
    }
  });

  function getReferenceLink(stepEndpoint) {
    const key = `${stepEndpoint.method.toLowerCase()} ${stepEndpoint.path}`;
    let link = linkMap[key];

    if (link === undefined) {
      link = getApiReferenceLink(stepEndpoint);
    }

    return link;
  }

  function getApiReferenceLink(stepEndpoint) {
    let link;
    const endpointBasepath = getBasepath(stepEndpoint.path);

    apis.data.forEach(function (api) {
      const apiSpec = api.apiSpec;
      const baseUrl = apiSpec.servers[0].url;

      if (baseUrl.endsWith(endpointBasepath)) {
        link = `/apis/${api.id}/reference`;
      }
    });

    return link;
  }

  return (
    <>
      <ReactMarkdown
        components={{
          code: (props) => <MarkdownRenderer {...props} />,
        }}
        children={props.description}
      />

      {props.steps.map((step, index, steps) => {
        const refLink = getReferenceLink(step.endpoint);
        step.refLink = refLink;
        if (index === steps.length - 1) {
          return <FlowStep key={step.endpoint.id} step={step} />;
        }
        return <FlowStep key={step.endpoint.id} step={step} hasNextStep />;
      })}
    </>
  );
}

export default FlowOverview;
