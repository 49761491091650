import { Divider, Grid, GridCol, Heading, NavHorizontal, NavItem, NavSide } from '@flixbus/honeycomb-react';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import useFlowService from '../../hooks/useFlowService';
import { getFlowNameFromId } from '../../utils/wordUtils';
import FlowGettingStarted from '../basics/getting-started/FlowGettingStarted';
import './FlowDetails.css';
import FlowDetailsHeader from './FlowDetailsHeader';
import FlowOverview from './FlowOverview';

function FlowDetails() {
  const { id } = useParams();
  const flowName = getFlowNameFromId(id);
  const { getFlow, getAllFlows } = useFlowService();

  const isGettingStarted = useLocation().pathname.split('/').pop().toLowerCase() === 'getting-started';

  const flow = useQuery([`flow${id}`], () => getFlow(id));
  const flowsPreview = useQuery([`flows`], () => getAllFlows());

  if (flow.isLoading || flowsPreview.isLoading) {
    return <div>Loading...</div>;
  }

  if (flow.isError || flowsPreview.isError) {
    return <div>Data could not be loaded</div>;
  }

  const flowContent = isGettingStarted ? (
    <FlowGettingStarted />
  ) : (
    <FlowOverview
      description={flow.data.data.description}
      steps={flow.data.data.steps}
      flowApis={flow.data.data.apis}
    />
  );

  return (
    <section className="flow-details">
      <Grid gutterSize={'2'} extraClasses="flow-grid" align="top">
        <GridCol size={2} extraClasses="flow-secondary-nav">
          <Heading size={3}>Flows</Heading>
          <NavSide aria-label="Flow list">
            {flowsPreview.data.data.map((flow) => {
              return (
                <NavItem key={flow.id} to={`/flows/${flow.id}/overview`} RouterLink={NavLink}>
                  {flow.name}
                </NavItem>
              );
            })}
          </NavSide>
        </GridCol>
        <GridCol key="grid-btm-b" size={10} extraClasses="hcr-has-text-left flow-content">
          <FlowDetailsHeader
            breadcrumbs={[
              { name: 'Home', link: '/' },
              { name: 'Flows', link: '/flows' },
              { name: flowName, link: `/flows/${id}/overview` },
            ]}
            flow={flow.data}
          />
          <Divider />

          <NavHorizontal aria-label="Shortcuts">
            <NavItem to={`/flows/${id}/overview`} RouterLink={NavLink}>
              Overview
            </NavItem>
            <NavItem to={`/flows/${id}/getting-started`} RouterLink={NavLink}>
              Getting Started
            </NavItem>
          </NavHorizontal>
          {flowContent}
        </GridCol>
      </Grid>
    </section>
  );
}

export default FlowDetails;
