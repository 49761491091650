import { useMsal } from '@azure/msal-react';
import { Box, Button, FormRow, Grid, GridCol, MainWrapper, ThemeWrapper } from '@flixbus/honeycomb-react';
import React from 'react';

import { ReactComponent as FlixDevLogo } from '../../assets/img/flixDevLogo.svg';
import { ReactComponent as FlixDevMaintenanceLogo } from '../../assets/img/flixDevMaintenanceLogo.svg';
import { request } from '../../authConfig';
import './Login.css';

function Login() {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(request).catch((e) => {
      console.log(e);
    });
  };

  return (
    <ThemeWrapper>
      <MainWrapper>
        <div className="login">
          <div className="login-modal">
            <Grid>
              <GridCol push={1} size={9} pushSm={4} sm={4}>
                <Box className="login-box">
                  <div className="login-box-logo">
                    <FlixDevLogo />
                    <FlixDevMaintenanceLogo className="hcr-space-4-top" />
                  </div>
                  <div className="hcr-space-4-top hcr-space-4-right hcr-space-4-left ">
                    <FormRow extraClasses="hcr-space-flush-bottom hcr-has-text-centered">
                      <Button appearance="primary" onClick={() => handleLogin()}>
                        SSO Login
                      </Button>
                    </FormRow>
                    <p>
                      In case of login issues please contact our{' '}
                      <a href="mailto:partner-integration@flixbus.com">partner integration</a> team.
                    </p>
                  </div>
                </Box>
              </GridCol>
            </Grid>
          </div>
        </div>
      </MainWrapper>
    </ThemeWrapper>
  );
}

export default Login;
